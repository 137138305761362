import { Injectable, WritableSignal, computed, inject, signal } from "@angular/core";
import { ApiService } from "../../../shared/api.service";
import { tap } from "rxjs";
import { NotificationService } from "../../../shared/notification/notification.service";
import { RequestHandlerParams } from "../../../shared/types/api-service.types";
import { AccountGetResponse } from './profile.types';
import { ApiResponse } from "../../../shared/common/types";
import { AuthService } from "../../../authentication/auth.service";
import { LocalStorageService } from "../../../shared/local-storage.service";
import { LOCALSTORAGE_KEYS } from "../../../shared/constants/databases";
import { Session } from "../../../shared/types/session.type";
import { DeliveriesService } from "../deliveries/deliveries.service";
import { SignalsStoreService } from "../../../shared/signals-store.service";
import { AccountService } from "../../../shared/account.service";
import { formatDateToReadableString } from "../../../shared/utils/formatting";
import { ModalContentService } from "../../../shared/modal-content/modal-content.service";
import { OrderService } from "../../../shared/order.service";
import { SettingsService } from "../../settings.service";
import { afterDeliveryUpdates } from "../../../shared/common/utils";

@Injectable()
export class ProfileService {

    private apiService = inject(ApiService);
    private notificationService = inject(NotificationService);
    private authService = inject(AuthService);
    private localStorageService = inject(LocalStorageService);
    private deliveryInfoService = inject(DeliveriesService);
    private signalStoreService = inject(SignalsStoreService);
    private accountService = inject(AccountService);
    private orderService = inject(OrderService);
    #modalService = inject(ModalContentService);
    #orderService = inject(OrderService);
    #settingsService = inject(SettingsService);

    private profile: WritableSignal<AccountGetResponse> = signal(null as any);
    profileData = computed(this.profile);
    private membserhipOptions: WritableSignal<any> = signal(null);
    membserhipOptionsData = computed(this.membserhipOptions);

    endpoints = {
        base: `/account`,
        eps: {
            changeEmail: '/email',
            changePassword: '/password',
            putPreferences: '/preferences',
            membershipOptions: '/membership-options'
        },
    };

    get() {
        this.#settingsService.setIsWaitingForAPIResponse(true);
        const params: RequestHandlerParams = {
            method: 'GET',
            endpoint: this.endpoints.base,
            apiV3: true
        };
        return this.apiService.handleRequest<ApiResponse<AccountGetResponse>>(params).pipe(
            tap((response: ApiResponse<AccountGetResponse>) => this.profile.set(response.data)),
            tap(() => this.#settingsService.setIsWaitingForAPIResponse(false))
        );
    }

    put(profile: any) {
        this.#settingsService.setIsWaitingForAPIResponse(true);
        const params: RequestHandlerParams = {
            method: 'PUT',
            endpoint: this.endpoints.base,
            body: profile,
            apiV3: true
        };
        return this.apiService.handleRequest<ApiResponse<AccountGetResponse>>(params).pipe(
            tap((response: ApiResponse<AccountGetResponse>) => this.#afterProfileUpdate(response)),
            tap(() => this.#settingsService.setIsWaitingForAPIResponse(false))
        )
    }

    #afterProfileUpdate(response: ApiResponse<AccountGetResponse>) {
        this.notificationService.show({ text: response.message, type: 'success' });

        this.profile.update((profile: any) => !response?.data ? profile : this.#setUpProfileUpdateResponse(profile, response.data));

        this.membserhipOptions.set([]);
        this.getMembershipOptions().subscribe();

        this.accountService.getMembershipPermissions().subscribe();

        this.#updateLocalStorage(response.data);

        this.deliveryInfoService.getDeliveryZoneInfo().pipe(
            tap(() => {
                afterDeliveryUpdates({
                    orderCutoffExceeded: this.profile()?.orderCutoffExceeded ?? null,
                    orderRescheduled: this.profile()?.orderRescheduled ?? null,
                    deliveryZoneInfo: this.deliveryInfoService.deliveryZoneInfo(),
                    orderService: this.#orderService,
                    modalService: this.#modalService,
                    isUpdatingFromProfile: true,
                    orderAlreadyConfirmed: { showMessage: !!response.data.orderConfirmed, message: response.data.notificationMessage || '' }
                })
            })
        ).subscribe();

    }


    #setUpProfileUpdateResponse(currentData: AccountGetResponse, newData: AccountGetResponse): AccountGetResponse {
        return {
            ...currentData,
            ...newData,
            orderCutoffExceeded: newData.orderCutoffExceeded ? {
                ...newData.orderCutoffExceeded,
                _readableDeliveryDate: formatDateToReadableString(newData.orderCutoffExceeded.deliveryDate),
            } : null,
            orderRescheduled: newData.orderRescheduled?.map(o => {
                return {
                    ...o,
                    _readablePrevDeliveryDate: formatDateToReadableString(o.prevDeliveryDate),
                    _readableNewDeliveryDate: formatDateToReadableString(o.newDeliveryDate)
                }
            })?.reverse() ?? []
        };
    }

    #updateLocalStorage(data: AccountGetResponse) {
        // Update the local storage data:
        const currentStoredSession: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION) || null;
        if (currentStoredSession) {
            const newSessionData = {
                ...currentStoredSession,
                address: { ...currentStoredSession.address, ...data.address, stateCode: data.address.state },
                accountInfo: { ...currentStoredSession.accountInfo, ...data.accountInfo },
                settings: { ...currentStoredSession.settings, ...data.settings }
            };

            this.localStorageService.set(LOCALSTORAGE_KEYS.SESSION, newSessionData);
            this.signalStoreService.sessionSignal.set(this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION) || null);
            this.signalStoreService.isAddressRequired.set(data.settings.requireAddress ?? true);
            this.signalStoreService.isLimitedUser.set(!!(data.settings.isLimited ?? false));
        }
    }

    putPreferences(profile: any) {
        const params: RequestHandlerParams = {
            method: 'PUT',
            endpoint: `${this.endpoints.base}${this.endpoints.eps.putPreferences}`,
            body: profile,
            apiV3: true
        };
        return this.apiService.handleRequest<any>(params);
    }

    changePassword(current: string, password: string) {
        const params: RequestHandlerParams = {
            method: 'PATCH',
            endpoint: `${this.endpoints.base}${this.endpoints.eps.changePassword}`,
            body: { current, password },
            apiV3: true
        };
        return this.apiService.handleRequest<any>(params).pipe(
            tap((response: any) => {
                this.notificationService.show({ text: response.message, type: 'success' });
                setTimeout(() => {
                    this.authService.logout();
                }, 2000);
            })
        )
    }

    changeEmail(email: string) {
        const params: RequestHandlerParams = {
            method: 'PATCH',
            endpoint: `${this.endpoints.base}${this.endpoints.eps.changeEmail}`,
            body: { email },
            apiV3: true
        }
        return this.apiService.handleRequest<any>(params).pipe(
            tap((response: any) => {
                this.notificationService.show({ text: response.message, type: 'success' });
                setTimeout(() => {
                    this.authService.logout();
                }, 3000);
            })
        )
    }

    getMembershipOptions() {
        const params: RequestHandlerParams = {
            method: 'GET',
            endpoint: `${this.endpoints.base}${this.endpoints.eps.membershipOptions}`,
            apiV3: true
        };

        return this.apiService.handleRequest<any>(params).pipe(
            tap((response) => this.membserhipOptions.set(response.data))
        );
    }
}