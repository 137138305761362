import { Injectable, inject } from "@angular/core";
import { LocalStorageService } from "../local-storage.service";
import { LOCALSTORAGE_KEYS } from "../constants/databases";
import { Session } from "../types/session.type";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { tap } from "rxjs";

declare global {
  interface Window {
    klaviyo: any;
  }
}

@Injectable({ providedIn: 'root' })
export class KlaviyoService {
  private localStorageService = inject(LocalStorageService);
  private http = inject(HttpClient);

  trackEvent(event: string, properties: any) {

    const customerData: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION) || null;
    const hasSession = !!customerData?.accountInfo?.id
    if (!hasSession) return;

    const companyId = environment.apis.klaviyo.publicApiKey;
    if (!companyId || !environment.production) return;

    const headers = new HttpHeaders({
      accept: 'application/json',
      revision: '2024-07-15',
      'ContentType': 'application/json',
    });

    const url = `https://a.klaviyo.com/client/events/?company_id=${companyId}`;

    try {
      this.http.post(url,
        {
          data: {
            type: 'event',
            attributes: {
              properties,
              metric: { data: { type: 'metric', attributes: { name: event } } },
              profile: {
                data: {
                  type: 'profile',
                  attributes: {
                    email: customerData.accountInfo.email,
                    phone_number: customerData.accountInfo.phone,
                    first_name: customerData.accountInfo.firstName,
                    last_name: customerData.accountInfo.lastName,
                    location: {
                      address1: customerData.address?.street || '',
                      address2: customerData.address?.additional || '',
                      city: customerData.address?.city || '',
                      zip: customerData.address?.zip || '',
                    }
                  }
                }
              }
            }
          }
        }, { headers }).pipe(
          tap((res: any) => {
            // console.log(res);
          })
        ).subscribe();
    } catch (error) {
      console.log('Error en trackEvent: ', error);
    }
  }
}
