import { Injectable, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { EMPTY, Observable, from, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, shareReplay, switchMap, tap } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { documentId } from 'firebase/firestore';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FirebaseCrudService {
  private firestore = inject(AngularFirestore);
  private fireauth = inject(AngularFireAuth);
  private storage = inject(AngularFireStorage);

  private ensureAuth(): Observable<void> {
    return from(this.fireauth.currentUser).pipe(
      switchMap(user => {
        if (!user) {
          return from(this.fireauth.signInAnonymously()).pipe(
            catchError(error => {
              console.error('FIREBASE AUTH ERROR', error);
              Sentry.captureException(error);
              return throwError(() => error);
            }),
            map(() => undefined)
          );
        }
        return of(undefined);
      })
    );
  }

  getAll<T>(collection: string): Observable<T[]> {
    return this.ensureAuth().pipe(
      switchMap(() =>
        this.firestore.collection(collection).snapshotChanges().pipe(
          map(actions =>
            actions.map(a => ({
              id: a.payload.doc.id,
              ...a.payload.doc.data() as T
            }))
          )
        )
      ),
      catchError(error => {
        this.logError(`FIREBASE GET ALL ERROR >> COLECCTION: ${collection}`, { collection }, error);
        return of([]);
      }),
      shareReplay(1) // Avoid multiple unnecessary calls to Firestore
    );
  }

  getById(collection: string, id: string): Observable<any> {
    return this.ensureAuth().pipe(
      switchMap(() => this.firestore.collection(collection, ref => ref
        .where(documentId(), '>=', id)
        .where(documentId(), '<=', id + '-\uf8ff')).valueChanges()),
      catchError(error => {
        this.logError(`FIREBASE GET BY ID ERROR >> COLLECTION: ${collection} - ID: ${id}`, { collection, id }, error);
        return of(null);
      }),
      shareReplay(1) // Avoid multiple unnecessary calls to Firestore
    );
  }

  getByIdWithoutFilter(collection: string, id: string): Observable<any> {

    return this.ensureAuth().pipe(
      switchMap(() => this.firestore
        .collection(collection)
        .doc(id)
        .valueChanges()),
      catchError(error => {
        this.logError(`FIREBASE GET BY ID ERROR >> COLLECTION: ${collection} - ID: ${id}`, { collection, id }, error);
        return of(null);
      }),
      shareReplay(1) // Avoid multiple unnecessary calls to Firestore
    );
  }

  getHeroVideo(): Observable<string> {

    if (!environment.production) return of('');

    return this.ensureAuth().pipe(
      switchMap(() => this.storage.ref('MEF Banner Video V2.mov').getDownloadURL().pipe(
        tap({
          error: (error) => this.logError('STORAGE GET ERROR', { file: 'MEF Banner Video V2.mov' }, error)
        })
      )),
      catchError(error => {
        this.logError(`STORAGE GET ERROR`, { file: 'MEF Banner Video V2.mov' }, error);
        return of(null);
      }),
      shareReplay(1) // Avoid multiple unnecessary calls to Firestore
    )

  }

  add(collection: string, docId: string | number, data: any): void {
    this.ensureAuth().pipe(
      switchMap(() => this.firestore.collection(collection).doc(docId.toString()).set(data)),
      catchError(error => {
        this.logError(`FIREBASE ADD ERROR >> COLLECTION: ${collection} - ID: ${docId}`, { collection, docId, data }, error);
        return EMPTY;
      })
    ).subscribe()
  }

  addWithoutDocId(collection: string, data: any): void {
    this.ensureAuth().pipe(
      switchMap(() => this.firestore.collection(collection).add(data)),
      catchError(error => {
        this.logError(`FIREBASE ADD WITHOUT ID ERROR >> COLLECTION: ${collection}`, { collection, data }, error);
        return EMPTY; // Prevents the error from interrupting the execution of the observable
      })
    ).subscribe()
  }

  update(collection: string, id: string | number, data: any) {
    this.ensureAuth().pipe(
      switchMap(() => this.existsDoc(collection, id.toString())),
      filter(exits => exits),
      mergeMap(() => this.firestore.collection(collection).doc(id.toString()).update(data)),
      catchError(error => {
        this.logError(`FIREBASE UPDATE ERROR >> COLLECTION: ${collection} - ID: ${id}`, { collection, id, data }, error);
        return EMPTY; // Prevents the error from interrupting the execution of the observable
      })
    ).subscribe();
  }

  updateSubkeys(payload: FirebaseUpdateSubKeys): void {
    const { collection, docId, updateData } = payload;
    this.ensureAuth().pipe(
      switchMap(() => this.existsDoc(collection, docId)),
      filter(exists => exists),
      mergeMap(() => this.firestore.collection(collection).doc(docId.toString()).update(updateData)),
      catchError(error => {
        this.logError(`FIREBASE UPDATE SUBKEYS ERROR >> COLLECTION: ${collection} - ID: ${docId}`, payload, error);
        return EMPTY; // Prevents the error from interrupting the execution of the observable
      })
    ).subscribe();
  }

  updateSubkey(payload: FirebaseUpdateSubKey): void {
    const { collection, docId, subKeyPath, newValue } = payload;
    this.ensureAuth().pipe(
      switchMap(() => this.existsDoc(collection, docId)),
      filter(exists => exists),
      mergeMap(() => {
        const updateData = { [subKeyPath]: newValue };
        return from(this.firestore.collection(collection).doc(docId.toString()).update(updateData));
      }),
      catchError(error => {
        this.logError(`FIREBASE UPDATE SUBKEY ERROR >> COLLECTION: ${collection} - ID: ${docId} - PATH: ${subKeyPath}`, payload, error);
        return EMPTY; // Prevents the error from interrupting the execution of the observable
      })
    ).subscribe();
  }

  existsDoc(collection: string, docId: string): Observable<any> {
    return this.firestore.collection(collection).doc(docId).get().pipe(
      tap({
        next: snapshot => !!snapshot.exists,
        error: error => {
          this.logError(`FIREBASE EXISTS ERROR >> COLLECTION: ${collection} - ID: ${docId}`, { collection, docId }, error);
          of(false);
        }
      })
    )
  }

  delete(collection: string, id: string) {
    this.ensureAuth().pipe(
      switchMap(() => this.firestore.collection(collection).doc(id.toString()).delete()),
      tap({ error: error => this.logError(`FIREBASE DELETE ERROR >> COLLECTION: ${collection} - ID: ${id}`, { collection, id }, error) })
    ).subscribe()
  }

  private logError(message: string, context: any, error: any): void {
    console.error(message, error);
    Sentry.setContext("firebase", { ...context, errorMessage: error.message, errorStack: error.stack });
    Sentry.captureException(new Error(`${message}: ${error.message}`));
  }
}

export interface FirebaseUpdateSubKey {
  collection: string;
  docId: string;
  subKeyPath: string;
  newValue: any;
}

export interface FirebaseUpdateSubKeys {
  collection: string;
  docId: string;
  updateData: any;
}
